.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500;600&display=swap');

.cover-body{
  background-image: url(../src/image/back2.jpg);
 background-repeat: no-repeat;
 background-size: cover;
 overflow: hidden;
}

.nav-justified .nav-item .nav-link{
   width: 85%;
    height: auto;
    border-radius: 11px;
}

@media (max-width: 767.98px){
 
  .hero-left{
   display: none;
  }

  .card-box{
   width: 100%;
  }

  .rightside{
    padding: 40px 0;
  }

  .heading {
    font-size: 40px !important ;
}
.taby-tabs{
  position: relative;
  left: -5%;
  width: 80% !important;
}

.btn-12 span{
  width: 60% !important;
}

.btn-12{
  left: 65% !important;

}

.contact-form{
  width: 100% !important;
}
.contact-form .submit-button-wrapper {
  margin-left: 30% !important;
}

.card.is-active .card-jobtitle {
  left: 30% !important;
}

.nav-justified .nav-item .nav-link{
  width: 85% !important;
  height: 100% !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
}

}


 


 @media (max-width: 991.98px) { 
  .hero-title>h1{
    font-size: 2.2rem !important;
  }

  .hero-title {
    left: 8% !important;
}
.heading{
  font-size: clamp(3rem, 6vw, 4.2rem) !important;
}



  }


@media (min-width: 576px) { 
    
  .heading {
    font-size: clamp(4rem, 7vw, 4rem) ;
}
.taby-tabs{
  width: 75%;
}

#project-tab {
  width: 85% !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
}


 }